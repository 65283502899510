import React, { useContext, useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby";
import parse from 'html-react-parser';

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

// import AOS from 'aos';
// import 'aos/dist/aos.css';

//sections
import AboutSection1 from './sections/about/aboutSection1';
import AboutSection2 from './sections/about/aboutSection2';
import AboutSection3 from './sections/about/aboutSection3';
import AboutSection4 from './sections/about/aboutSection4';
import BlogSection from './sections/BlogSection';

const About = ({data}) => {
  const mainData = data.allWpPage.nodes[0].aboutACF;
  // setTimeout(() => {
  //   window.location.reload()
  //   // setLoader(false)
  // },1) 

  // useEffect(() => {
  //   AOS.init();
  // },[])

  return (
    <Layout>
      <Seo title="About" />
      <div className="page-wrapper about-page" id="about-page-wrapper">
        <AboutSection1 
            mainTitle={parse(mainData.aboutSection1Title)}
            mainDescription={parse(mainData.aboutSection1Description)}
            image11x={
              {
                sourceUrl: mainData.aboutSection1Image11x.sourceUrl,
                altText: mainData.aboutSection1Image11x.altText,
                width: mainData.aboutSection1Image11x.width,
              }
            }
            image12x={
              {
                sourceUrl: mainData.aboutSection1Image12x.sourceUrl,
                altText: mainData.aboutSection1Image12x.altText,
                width: mainData.aboutSection1Image12x.width,
              }
            }
            image21x={
              {
                sourceUrl: mainData.aboutSection1Image21x.sourceUrl,
                altText: mainData.aboutSection1Image21x.altText,
                width: mainData.aboutSection1Image21x.width,
              }
            }
            image22x={
              {
                sourceUrl: mainData.aboutSection1Image22x.sourceUrl,
                altText: mainData.aboutSection1Image22x.altText,
                width: mainData.aboutSection1Image22x.width,
              }
            }
            image31x={
              {
                sourceUrl: mainData.aboutSection1Image31x.sourceUrl,
                altText: mainData.aboutSection1Image31x.altText,
                width: mainData.aboutSection1Image31x.width,
              }
            }
            image32x={
              {
                sourceUrl: mainData.aboutSection1Image32x.sourceUrl,
                altText: mainData.aboutSection1Image32x.altText,
                width: mainData.aboutSection1Image32x.width,
              }
            }
            image41x={
              {
                sourceUrl: mainData.aboutSection1Image41x.sourceUrl,
                altText: mainData.aboutSection1Image41x.altText,
                width: mainData.aboutSection1Image41x.width,
              }
            }
            image42x={
              {
                sourceUrl: mainData.aboutSection1Image42x.sourceUrl,
                altText: mainData.aboutSection1Image42x.altText,
                width: mainData.aboutSection1Image42x.width,
              }
            }
        />
        {/* <AboutSection2 
            mainTitle={parse(mainData.aboutSection2Title)}
            mainDescription={parse(mainData.aboutSection2Description)}
            list={mainData.aboutSection2List}
            awardsList={mainData.aboutSection2AwardsList}
        /> */}
        <AboutSection3 
            mainTitle={parse(mainData.aboutSection3Title)}
            mainDescription={parse(mainData.aboutSection3Description)}
            teamList={mainData.aboutSection3ImageList}
        />
        <AboutSection4 
            mainTitle={parse(mainData.aboutSection4Title)}
            mainDescription={parse(mainData.aboutSection4Description)}
            image1x={
              {
                sourceUrl: mainData.aboutSection4Image1x.sourceUrl,
                altText: mainData.aboutSection4Image1x.altText,
                width: mainData.aboutSection4Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.aboutSection4Image2x.sourceUrl,
                altText: mainData.aboutSection4Image2x.altText,
                width: mainData.aboutSection4Image2x.width,
              }
            }
        />
        <BlogSection />
      </div>
    </Layout>
  )
}

export default About;

export const indexQuery = graphql`
{
  allWpPage(filter: {slug: {eq: "about"}}) {
    nodes {
        aboutACF {
            aboutSection1Title
            aboutSection1Description
            aboutSection1Image11x {
              altText
              sourceUrl
              width
            }
            aboutSection1Image12x {
              altText
              sourceUrl
              width
            }
            aboutSection1Image21x {
              altText
              sourceUrl
              width
            }
            aboutSection1Image22x {
              altText
              sourceUrl
              width
            }
            aboutSection1Image31x {
              altText
              sourceUrl
              width
            }
            aboutSection1Image32x {
              altText
              sourceUrl
              width
            }
            aboutSection1Image41x {
              altText
              sourceUrl
              width
            }
            aboutSection1Image42x {
              altText
              sourceUrl
              width
            }
            aboutSection2Title
            aboutSection2Description
            aboutSection2List {
              label
              value
            }
            aboutSection2AwardsList {
              image {
                altText
                sourceUrl
                width
              }
            }
            aboutSection3Title
            aboutSection3Description
            aboutSection3ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            name
            designation
            }
            aboutSection4Title
            aboutSection4Description
            aboutSection4Image1x {
              altText
              sourceUrl
              width
            }
            aboutSection4Image2x {
              altText
              sourceUrl
              width
            }
          }
    }
  }
}`

